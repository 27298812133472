.nav__span {
  color: #fff;
  text-align: center;
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
}

.nav__span-container {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.nav__button {
  color: #000;
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border: none;
  background: transparent;
  transition: 0.2s ease;
  padding: 0;
  cursor: pointer;
}

.nav__button:hover {
  opacity: 0.5;
}

.nav__profile {
  display: flex;
  gap: 16px;
  align-items: center;
  transition: 0.2s ease;
  text-decoration: none;
}

.nav__profile:hover {
  opacity: 0.5;
}

.nav__profile-name {
  color: #000;
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
  margin: 0;
}

.nav__profile-image {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.5) 50%;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 640px) {
  .nav {
    display: none;
  }
}
