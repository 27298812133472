.item__image {
  width: 100%;
  object-fit: cover;
  max-height: 498px;
}

.item__section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 32px;
}

.item__name {
  color: #000;
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 12px;
}

.item__weather {
  color: #000;
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.item__delete-button {
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ff4d00;
  border: none;
  background: transparent;
  padding: 0;
  transition: 0.2s ease;
}

.item__delete-button:hover {
  transform: scale(1.1, 1.1);
}
