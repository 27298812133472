.card__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  aspect-ratio: 1;
  max-width: 500px;
  width: 100%;
}

.card__header {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 15px 5px;
  z-index: 1;
}

.card__name {
  text-align: center;
  margin: 0;
  padding: 2px 12px;
  border-radius: 4px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.card__like-button {
  width: 20px;
  height: 20px;
  border: none;
  background: transparent url(../../images/likeButton.svg) no-repeat center;
  transition: 0.2s ease;
  cursor: pointer;
}

.card__like-button:hover {
  transform: scale(1.1, 1.1);
}

.card__like-button_liked {
  background: url(../../images/likeButtonLiked.svg) no-repeat center;
}

.card__image {
  transition: 0.3s ease;
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.card__image:hover {
  opacity: 0.7;
}
