.side__section {
  display: flex;
  margin: 0;
  flex-direction: column;
}

.side__profile-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.side__logo {
  width: 56px;
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  object-fit: cover;
}

.side__title {
  color: #000;
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin: 0;
}

.side__options-container {
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  width: 180px;
}

.side__button {
  color: #000;
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
  margin: 0;
  transition: 0.2s ease;
  border: none;
  background-color: #f3f3f3;
}

.side__button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.side__span-container {
  width: 56px;
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.side__span {
  color: #fff;
  text-align: center;
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  width: 56px;
  height: 56px;
  flex-direction: column;
  justify-content: center;
}
