.weather {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  max-width: 1360px;
  height: 80px;
  box-shadow: 0px 5px 10px rgb(0, 0, 0, 0.25);
}

.weather__info {
  position: absolute;

  color: #fff;
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  top: 20px;
  left: 16px;
  z-index: 1;
}

.weather__image {
  position: absolute;
  top: 0;
  right: 0;
}
