.profile__content {
  display: flex;
  max-width: 1360px;
  margin: 47px 0 0;
  padding: 0;
  gap: 116px;
  min-height: calc(100vh - 191px);
}

@media screen and (max-width: 900px) {
  .profile__content {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}
