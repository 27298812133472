.footer {
  margin-top: 70px;
}

.footer__text {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  max-width: 1360px;
}

.footer__link {
  color: #000;
}
