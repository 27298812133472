.main {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1360px;
}

.main__title {
  color: #000;
  font-family: "Cabinet Grotesk", sans-serif;
  display: flex;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 34px;
  margin-top: 40px;
}

.main__items {
  max-width: 1360px;
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  min-height: calc(100vh - 401px);
}

@media (min-width: 1024px) {
  .main__items {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1024px) {
  .main__items-clothes {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1024px) {
  .main__items {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .main__items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .main__items {
    grid-template-columns: 1fr;
  }
}
