.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 40px;
  padding: 0;
  max-width: 1360px;
  margin-bottom: 28px;
}

.header__main {
  display: flex;
  gap: 24px;
  align-items: center;
}

.header__logo {
  cursor: default;
  cursor: pointer;
  transition: 0.2s;
}

.header__logo:hover {
  transform: scale(1.1, 1.1);
}

.header__date {
  color: #000;
  font-family: "Cabinet Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.header__profile {
  gap: 20px;
  display: flex;
  align-items: center;
}

.header__hamburger {
  display: none;
  padding: 2px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  cursor: pointer;
  background-image: url(../../images/burgerButton.svg);
  color: #000;
  width: 30px;
  transition: 0.2s ease;
}

.header__hamburger:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

@media (max-width: 640px) {
  .header__profile {
    display: none;
  }

  .header__hamburger {
    display: block;
  }
}
